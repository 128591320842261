<template>
  <b-card title="Warranty Registration">
    <b-col cols="12">
      <b-row>
        <b-col cols="4">
        </b-col>
        <b-col cols="4"/>
        <b-col cols="4">
          <b-form-input
            v-model="search"
            type="search"
            placeholder="Type to Search"
          />
        </b-col>
        <!-- <b-col cols="12" class="mb-2">
          <div class="filter-responsive">
            <div class="input-group">
              <div class="input-group-prepend">
                <b-dropdown style="margin: 0 !important;" id="dropdown-1" :text="selectedFilter" class="m-md-2">
                  <b-dropdown-item @click="dropDownMenuClick" field="fullName">Name</b-dropdown-item>
                  <b-dropdown-item @click="dropDownMenuClick" field="email">Email</b-dropdown-item>
                  <b-dropdown-item @click="dropDownMenuClick" field="phone">Phone</b-dropdown-item>
                  <b-dropdown-item @click="dropDownMenuClick" field="model">Model</b-dropdown-item>
                  <b-dropdown-item @click="dropDownMenuClick" field="serial">Serial</b-dropdown-item>
                  <b-dropdown-item @click="dropDownMenuClick" field="barcode">Barcode</b-dropdown-item>
                </b-dropdown>
              </div>
              <b-form-input v-model="toFilter" placeholder="Filter.."/>
            </div>

            <b-button @click="doFilter" variant="primary">Filter</b-button>
            <b-button @click="clearFilter" variant="primary">Clear</b-button>
          </div>
        </b-col> -->

        <!-- <b-col cols="12">
          <div class="filter-grid my-1">
            <div class="paperless-drop">
              <label>Status</label>
              <b-form-select @change="(value) => fireFiltering('warrantyType', value)" v-model="warrantyType" :options="warrantyOptions"></b-form-select>              
            </div>

            <div class="paperless-drop ">
              <label>Year</label>
              <b-form-select @change="(value) => fireFiltering('year', value)" v-model="year" :options="yearOptions"></b-form-select>
            </div>

            <div class="paperless-drop">
              <label>Month</label>
              <b-form-select :disabled="year === null" @change="(value) => fireFiltering('month', value)" v-model="month" :options="months"></b-form-select>
            </div> 
          </div>
        </b-col> -->

        <b-col style="margin: 15px 0 0 0" cols="12">
          <!-- <div class="d-flex flex-row mb-1">
            <span class="font-weight-bold">Successful Registrations : {{ statistic.registered }}</span>
            <span class="ml-1 font-weight-bold">Registration Errors : {{ statistic.issue }}</span>
          </div> -->

          <!-- <div class="d-flex justify-content-end mb-1">
            <b-button variant="primary" to="/admin/warranty/new">Add Warranty</b-button>
          </div>

          <div class="d-flex justify-content-end mb-1">
            <a :href="downloadUrl" target="_blank" class="btn btn-primary">Export Csv</a>
          </div> -->
          

          <b-table
            :items="individualWarranty"
            :fields="fields"
            :filter="search"
            :per-page="perPage"
            :busy.sync="busyLoadingWarranty"
            :current-page="currentPage"
            show-empty
            empty-text="Data Not Found"
            responsive
          >
            <!-- <template #cell(actions)="row">
              <b-button size="sm" @click="detailWarranty(row)" class="mr-1">
                Detail
              </b-button>

              <b-button @click="confirmToDelete(row.item)" variant="danger" :disabled="needDisableDeleteButton(row.item)" size="sm" class="mr-1">
                Delete
              </b-button>
            </template> -->

            <template #cell(cert)="row">
              <a href="#" @click.prevent="detailWarranty(row)" style="color: #6e6b7b; text-decoration: underline;">
                {{ `${row.item.cert}` }}
              </a>
            </template>

            <template #cell(address)="row">
              {{ row.item.address }} {{ row.item.address2 }} {{ row.item.address3 }} {{ row.item.buildingName }}
            </template>

            <template #cell(createdAt)="row">
              {{ row.item.createdAt | formatDate }}
            </template>

            <template #table-busy>
              <div class="text-center text-danger my-2">
                <b-spinner class="align-middle mr-2"></b-spinner>
                <strong>Loading...</strong>
              </div>
            </template>
          </b-table>
        </b-col>

        <b-col cols="12">
          <b-pagination
            :total-rows="totalData"
            :per-page="perPage"
            first-number
            last-number
            v-model="currentPage"
          />
        </b-col>
        <!-- Trigger -->
      </b-row>

      <!-- <b-modal hide-footer v-model="deleteModal">
        <p>Are you sure you want to delete ?</p>

        <b-button class="mt-2 mr-1" @click="doDelete">Yes</b-button>
        <b-button class="mt-2" @click="deleteModal = false">No</b-button>
      </b-modal> -->
    </b-col>
  </b-card>
</template>

<script>
import { mapActions, mapState } from 'vuex';
const queryString = require('query-string');

export default {
  data() {
    return {
      fields: [
        { key: 'cert', label: 'Certificate', filterable: true, sortable: true },
        { key: 'installerName', label: 'Installer', filterable: true, sortable: true },
        { key: 'address', label: 'Address', filterable: true, sortable: true },
        { key: 'createdAt', label: 'Registration Date' },
        
      ],
      search: '',
      currentPage: 1,
      perPage: 10,
      totalData: 1,
      individualWarranty:[],
      // statistic: {},
      // warrantyType: null,
      // warrantyOptions: [
      //   { value: null, text: 'All' },
      //   { value: 'Registered', text: 'Registered' },
      //   { value: 'Registration Issue', text: 'Registration Issue' },
      // ],
      // year: null,
      // month: null,
      // yearOptions: [
      //   { value: null, text: 'All' },
      // ],
      // months: [
      //   { value: null, text: 'All' },
      //   { value: 1, text: 'January' },
      //   { value: 2, text: 'February' },
      //   { value: 3, text: 'March' },
      //   { value: 4, text: 'April' },
      //   { value: 5, text: 'May' },
      //   { value: 6, text: 'June' },
      //   { value: 7, text: 'July' },
      //   { value: 8, text: 'August' },
      //   { value: 9, text: 'September' },
      //   { value: 10, text: 'October' },
      //   { value: 11, text: 'November' },
      //   { value: 12, text: 'December' },
      // ],
      // downloadUrl: '/api/v1/sales-and-purchase/drcc/download/csv',
      busyLoadingWarranty: true,
      // selectedFilter: 'Name',
      // selectedField: 'fullName',
      // toFilter: '',
    }
  },
  created() {
    // Setup Store To Save Filtering Information
    // this.getFilterPaperless('paperless-list');
    // this.currentPage = this.reactiveCurrentPage;
    // this.warrantyType = this.reactiveWarrantyType;
    // this.year = this.reactiveYear;
    // this.month = this.reactiveMonth;

    // let now = new Date();
    // let year = now.getFullYear();
    
    // this.yearOptions.push({ value: year, text: year });

    // for (let i = 1; i < 5; i++) {
    //   this.yearOptions.push({ value: year - i, text: year - i });
    // }
  },
  async mounted() {
    // console.log('email login',this.emailLogin)
    // this.getExternalWarranty({
    //   year: this.reactiveYear,
    //   month: this.reactiveMonth,
    //   status: this.reactiveWarrantyType,
    // }).then((data) => {
    //   this.busyLoadingWarranty = false;
    //   this.processMetadata(data.metadata);
    // });
    await this.getIndividualWarranty({email:this.emailLogin}).then((res) => {
      console.log({res})
      this.busyLoadingWarranty = false;
      this.totalData = res.length
      this.individualWarranty = res
    });

    // this.updateUrl();

    // this.getPaperlessStatistic().then((data) => {
    //   this.statistic = data.data;
    // }).catch((e) => {
    //   console.log(e);
    // });
  },
  // destroyed() {
  //   let field = this.selectedField;
  //   let filter = this.filterPaperless

  //   delete(filter[field]);

  // },
  methods: {
    detailWarranty(row) {
      // this.updateFilter({
      //   name: 'paperless-list',
      //   data: {
      //     currentPage: this.currentPage,
      //   }
      // });
      this.$router.push(`/detail-warranty/${row.item._id}`);
    },
    // needDisableDeleteButton(item) {
    //   if (item.registerStatus === 'Registered') {
    //     return true;
    //   }

    //   return false;
    // },
    ...mapActions({
      getExternalWarranty: 'warranty/getExternalWarranty',
      // deleteExternalWarranty: 'warranty/deleteExternalWarranty',
      getIndividualWarranty: 'warranty/getIndividualWarranty'
      // getPaperlessStatistic: 'warranty/getPaperlessStatistic',
    }),
    // savePaperlessFilter(key, value) {
    //   let tempFilter = this.filterPaperless;

    //   if (value === null) {
    //     tempFilter[key] = undefined;

    //     if (key === 'year') {
    //       tempFilter['month'] = undefined;
    //       this.month = null;
    //     }
    //   } else {
    //     tempFilter[key] = value;
    //   }
      
    //   this.updateFilter({
    //     name: 'paperless-list',
    //     data: { ...tempFilter }
    //   });
    // },
    // fireFiltering(key, value) {
    //   this.savePaperlessFilter(key, value);
    //   this.savePaperlessFilter('page', 1);

    //   let filter = this.filterPaperless;
    //   // this.updateUrl();

    //   this.busyLoadingWarranty = true;
    //   this.getExternalWarranty({ ...filter, status: filter.warrantyType }).then((data) => {
    //     this.busyLoadingWarranty = false;
       
    //   });
    // },
    // updateUrl() {
    //   let tempData = this.filterPaperless;
    //   let query = queryString.stringify({ ...tempData, status: tempData.warrantyType });
    //   this.downloadUrl = `/api/v1/sales-and-purchase/drcc/download/csv?${query}`
    // },
    // getDealerCode(data) {
    //   if (data.items.length > 0) {
    //     return data.items[0].dealerCode;
    //   }

    //   return undefined;
    // },
    // onSearch(data) {
    //   this.busyLoadingWarranty = true;
    // },
    // dropDownMenuClick(event) {
    //   let tempFilter = this.filterPaperless;
    //   let localField = this.selectedField;

    //   delete(tempFilter[localField]);

    //   this.updateFilter({
    //     name: 'paperless-list',
    //     data: { ...tempFilter }
    //   });

    //   this.selectedFilter = event.target.text;
    //   this.selectedField = event.target.getAttribute('field');
    // },
    // doFilter() {
    //   this.savePaperlessFilter(this.selectedField, this.toFilter);
    //   this.savePaperlessFilter('page', 1);

    //   let filter = this.filterPaperless;
    //   // this.updateUrl();

    //   this.busyLoadingWarranty = true;
    //   this.getExternalWarranty({ ...filter, status: filter.warrantyType }).then((data) => {
    //     this.busyLoadingWarranty = false;
        
    //   });
    // },
    // clearFilter() {
    //   let tempFilter = this.filterPaperless;
    //   let localField = this.selectedField;

    //   this.selectedField = 'model';
    //   this.selectedFilter = 'Model';
    //   this.toFilter = undefined;

    //   console.log(localField)

    //   delete(tempFilter[localField]);

    //   this.updateFilter({
    //     name: 'paperless-list',
    //     data: { ...tempFilter }
    //   });

    //   this.getExternalWarranty({ ...tempFilter, status: tempFilter.warrantyType }).then((data) => {
    //     this.busyLoadingWarranty = false;
    //     // this.processMetadata(data.metadata);
    //   });
    // },
    onPageChange(page) {
      this.savePaperlessFilter('page', page);

      let filter = this.filterPaperless;
      // this.updateUrl();

      this.busyLoadingWarranty = true;
      this.getExternalWarranty({ ...filter, status: filter.warrantyType }).then((data) => {
        this.busyLoadingWarranty = false;
      });
    },
  },
  computed: {
    emailLogin:{
      get() {
        return this.$store.getters['warranty/getEmail']
      },
      set(payload) {
        return this.$store.dispatch('warranty/setEmail',payload)
      }
    },
    ...mapState({
      // individualWarranty: (state) => {
      //   return state.individualWarranty
      // },
    //   filterPaperless: (state) => {
    //     if (state.filter.filterContainer['paperless-list'] !== undefined) {
    //       let paperlessFilter = state.filter.filterContainer['paperless-list'];
    //       return paperlessFilter;
    //     }

    //     return {};
    //   }
    }),
    // reactiveCurrentPage: {
    //   get() {
    //     if (this.filterPaperless.currentPage === undefined) {
    //       return 1;
    //     }

    //     return this.filterPaperless.currentPage;
    //   },
    //   set(newVal) {
    //     this.updateFilter({
    //       name: 'paperless-list',
    //       data: {
    //         currentPage: newVal
    //       }
    //     });

    //     this.currentPage = newVal;
    //   }
    // },
    // reactiveWarrantyType() {
    //   if (this.filterPaperless.warrantyType !== undefined) {
    //     return this.filterPaperless.warrantyType;
    //   }

    //   return null;
    // },
    // reactiveYear() {
    //   if (this.filterPaperless.year !== undefined) {
    //     return this.filterPaperless.year;
    //   }

    //   return null;
    // },
    // reactiveMonth() {
    //   if (this.filterPaperless.month !== undefined) {
    //     return this.filterPaperless.month;
    //   }

    //   return null;
    // },
  }
}
</script>

<style scoped>
.paperless-drop {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.paperless-drop label {
  font-weight: bold;
  text-align: left;
}

.filter-grid {
  display: grid;
  grid-template-columns: auto auto auto;
  /* grid-template-columns: 20%; */
  column-gap: 10px;
}

@media only screen and (max-width: 767px) {
  .paperless-drop {
    width: 100%;
  }

  .filter-grid {
    display: flex;
    flex-direction: column;
  }
}

.filter-responsive {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.filter-responsive button {
    margin: 0 0 0 5px;
  }

@media screen and (max-width: 480px) {
  .filter-responsive {
    flex-direction: column;
    justify-content: none;
  }

  .filter-responsive button {
    margin: 5px 0 0 0;
    width: 100px;
  }
}
</style>
